import { combineReducers } from "redux";

import web3Reucer from '../reducers/webReducer';
import walletConnection from '../reducers/walletConnection';
import plans from '../reducers/plans';

export default combineReducers({
    web3Reucer,
    walletConnection,
    plans
});