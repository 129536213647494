import {
    SET_WEB3,
    WALLET_CONNECT,
    SELECT_PLAN
} from '../constant/index';

export const setWeb3 = details => {
    return {
        type: SET_WEB3,
        payload: details
    };
};

export const setWallet = details => {
    return {
        type: WALLET_CONNECT,
        payload: details
    };
};

export const setPlan = details => {
    return {
        type: SELECT_PLAN,
        payload: details
    };
};



