// import constant
import {
    SELECT_PLAN
} from '../constant/index';

const initialState = {
    plan : "planA"
};

const plans = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_PLAN:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default plans;